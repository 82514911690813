import { Select, SelectItem, SelectTitle } from '@hqo/react-components-library';

import { ElementRendererProps } from '../../element-renderer.props.interface';
import { FormSelectElement } from '../../../form-definition';
import React from 'react';
import { useFieldFormikInfo } from '../formik.hooks';
import { useFieldRequired } from '../../../schema-context';
import { useIsEditMode } from '../../../form-renderer-config-context/hooks';
import { useSelectedItem } from './hooks';

interface SelectRendererProps extends ElementRendererProps {
  definition: FormSelectElement;
}

export const SelectRenderer: React.FC<SelectRendererProps> = ({
  definition, fieldKey,
}: SelectRendererProps): JSX.Element | null => {
  const isEditMode = useIsEditMode();
  const { key, value, handleChange } = useFieldFormikInfo(fieldKey);
  const dataCy = `${key}-select`;
  const selectedItem = useSelectedItem(definition.values, value);
  const isRequired = useFieldRequired(fieldKey);

  if (definition.hidden || (isEditMode && definition.editingOverrides?.hidden)) {
    return null;
  }

  return (
    <Select
      placeholder={definition.placeholder}
      value={value}
      selectedValueLabel={selectedItem?.label ?? ''}
      name={key}
      onChange={handleChange}
      titleComponent={definition.label && <SelectTitle title={definition.label} />}
      dataCy={dataCy}
      required={isRequired}
    >
      {
        definition.values.map((item): JSX.Element => (
          <SelectItem
            key={item.value}
            value={item.value}
            renderedValue={item.label}
            data-cy={`${dataCy}-item-${item.label}`}
          />
        ))
      }
    </Select>
  );
};

import { useMaxDate, useMinDate } from './hooks';

import { ElementRendererProps } from '../../element-renderer.props.interface';
import { FormDateElement } from '../../../form-definition';
import { Input } from '@hqo/react-components-library';
import React from 'react';
import { useFieldFormikInfo } from '../formik.hooks';
import { useFieldRequired } from '../../../schema-context';
import { useIsEditMode } from '../../../form-renderer-config-context/hooks';

interface DateRendererProps extends ElementRendererProps {
  definition: FormDateElement;
}

export const DateRenderer: React.FC<DateRendererProps> = ({
  definition, fieldKey,
}: DateRendererProps): JSX.Element | null => {
  const {
    key, value, error, handleChange, handleBlur,
  } = useFieldFormikInfo(fieldKey);
  const min = useMinDate(definition);
  const max = useMaxDate(definition);
  const isEditMode = useIsEditMode();
  const isRequired = useFieldRequired(fieldKey);

  if (definition.hidden || (isEditMode && definition.editingOverrides?.hidden)) {
    return null;
  }

  return (
    <Input
      label={definition.label}
      placeholder={definition.placeholder}
      variant={error ? 'error' : 'master'}
      type={definition.type}
      name={key}
      value={value}
      required={isRequired}
      onChange={handleChange}
      onBlur={handleBlur}
      errorText={error}
      min={min}
      max={max}
      dataCy={`${fieldKey}-input`}
    />
  );
};

import { ElementRendererProps } from '../../element-renderer.props.interface';
import { FormInputCommonProps, FormInputElement, FormInputProps } from '../../../form-definition';
import { FormField } from '@hqo/react-components-library';
import React from 'react';
import { useFieldFormikInfo } from '../formik.hooks';
import { useFieldRequired } from '../../../schema-context';
import { useIsEditMode } from '../../../form-renderer-config-context/hooks';

interface InputRendererProps extends ElementRendererProps {
  definition: FormInputElement;
}

function isFormInputProps(definition: FormInputElement): definition is FormInputProps & FormInputCommonProps {
  return definition.fieldType !== 'textarea';
}

export const InputRenderer: React.FC<InputRendererProps> = ({
  definition,
  fieldKey,
}: InputRendererProps): JSX.Element | null => {
  const isEditMode = useIsEditMode();
  const {
    key, value, error, handleChange, handleBlur,
  } = useFieldFormikInfo(fieldKey);
  const isRequired = useFieldRequired(fieldKey);

  if (definition.hidden || (isEditMode && definition.editingOverrides?.hidden)) {
    return null;
  }

  return (
    <FormField
      label={definition.label}
      fieldType={definition.fieldType}
      type={isFormInputProps(definition) ? definition.inputType : undefined}
      placeholder={definition.placeholder}
      variant={error ? 'error' : 'master'}
      name={key}
      value={value}
      readOnly={definition.readonly ?? definition.editingOverrides?.readonly}
      required={isRequired}
      onChange={handleChange}
      onBlur={handleBlur}
      errorText={error}
      dataCy={`${fieldKey}-input`}
    />
  );
};

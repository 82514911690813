import { ElementRenderers, ElementRenderersContext, defaultElementRenderers } from '../element-renderers-context';
import { FormRendererConfig, FormRendererConfigContext, defaultConfig } from '../form-renderer-config-context';
import React, { useMemo } from 'react';

import { FormSchema } from 'form-definition';
import { ItemsRenderer } from '../items-renderer';
import { SchemaContext } from '../schema-context';

export interface FormRendererProps {
  schema: FormSchema;
  elementRenderers?: Partial<ElementRenderers>;
  config?: Partial<FormRendererConfig>;
}

export const FormRenderer = ({ schema, elementRenderers, config }: FormRendererProps): JSX.Element | null => {
  const renderers = useMemo(() => ({
    ...defaultElementRenderers,
    ...elementRenderers ?? {},
  }), [elementRenderers]);

  const formRendererConfig = useMemo(() => ({
    ...defaultConfig,
    ...config ?? {},
  }), [config]);

  if (!schema.form?.items.length) {
    return null;
  }

  return (
    <SchemaContext.Provider value={schema}>
      <ElementRenderersContext.Provider value={renderers}>
        <FormRendererConfigContext.Provider value={formRendererConfig}>
          <ItemsRenderer items={schema.form.items} />
        </FormRendererConfigContext.Provider>
      </ElementRenderersContext.Provider>
    </SchemaContext.Provider>
  );
};

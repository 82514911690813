import { FormItem, ItemType } from '../form-definition';

import { ElementRenderer } from '../element-renderer';
import { ItemRendererProps } from './item-renderer.props.interface';
import { LabelRenderer } from '../label-renderer';
import React from 'react';
import { SectionRenderer } from '../section-renderer';

export interface Props {
  item: FormItem;
}

const itemRenderers: Record<ItemType, React.FC<ItemRendererProps>> = {
  element: ElementRenderer as React.FC<ItemRendererProps>,
  label: LabelRenderer as React.FC<ItemRendererProps>,
  section: SectionRenderer as React.FC<ItemRendererProps>,
};

export const ItemRenderer = ({ item }: Props): JSX.Element | null => {
  const Renderer = itemRenderers[item.type];

  if (!Renderer) {
    return null;
  }

  return (
    <>
      <Renderer definition={item} />
      <br />
    </>
  );
};

import { FormItem } from '../form-definition';
import { ItemRenderer } from '../item-renderer';
import React from 'react';

interface ItemsRendererProps {
  items: FormItem[];
  parentKey?: string;
}

export const ItemsRenderer = ({ items, parentKey = 'root' }: ItemsRendererProps): JSX.Element => (
  <>
    {
      items.map((item) => (
        <ItemRenderer item={item} key={`${parentKey}-item-${item.key}`} />
      ))
    }
  </>
);

ItemsRenderer.defaultProps = {
  parentKey: '',
};

import { ItemRendererProps } from '../item-renderer';
import { ItemsRenderer } from '../items-renderer';
import React from 'react';
import { SectionItem } from '../form-definition';
import { useSectionDefinition } from '../schema-context';

export interface SectionRendererProps extends ItemRendererProps {
  definition: SectionItem;
}

export const SectionRenderer: React.FC<SectionRendererProps> = (
  { definition }: SectionRendererProps,
): JSX.Element | null => {
  const sectionItem = useSectionDefinition(definition.key);

  if (!sectionItem) {
    return null;
  }

  return (
    <ItemsRenderer items={sectionItem.items} parentKey={`section-${definition.key}`} />
  );
};

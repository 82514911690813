import { ElementItem } from '../form-definition';
import { ItemRendererProps } from '../item-renderer';
import React from 'react';
import { useElementRenderer } from '../element-renderers-context';
import { useFieldDefinition } from '../schema-context';

interface Props extends ItemRendererProps {
  definition: ElementItem;
}

export const ElementRenderer: React.FC<Props> = ({ definition }: Props): JSX.Element | null => {
  const fieldDefinition = useFieldDefinition(definition.key);
  const Renderer = useElementRenderer(fieldDefinition?.type ?? null);

  if (!Renderer || !fieldDefinition) {
    return null;
  }

  return (
    <Renderer definition={fieldDefinition} fieldKey={definition.key} />
  );
};

import { CheckboxField, CheckboxWrapper } from '@hqo/react-components-library';

import { ElementRendererProps } from '../../element-renderer.props.interface';
import { FormCheckboxElement } from '../../../form-definition';
import React from 'react';
import { useFieldFormikInfo } from '../formik.hooks';
import { useIsEditMode } from '../../../form-renderer-config-context/hooks';

interface CheckboxRendererProps extends ElementRendererProps {
  definition: FormCheckboxElement;
}

export const CheckboxRenderer: React.FC<CheckboxRendererProps> = ({
  definition,
  fieldKey,
}: CheckboxRendererProps): JSX.Element | null => {
  const isEditMode = useIsEditMode();
  const { value, handleChange, key } = useFieldFormikInfo<boolean>(fieldKey, false);
  const readOnly = (definition.readonly || (isEditMode && definition.editingOverrides?.readonly)) ?? false;

  if (definition.hidden || (isEditMode && definition.editingOverrides?.hidden)) {
    return null;
  }

  return (
    <CheckboxWrapper title={definition.label}>
      <CheckboxField
        checked={value}
        onChange={handleChange}
        name={key}
        data-cy={`${key}-checkbox`}
        readOnly={readOnly}
      />
    </CheckboxWrapper>
  );
};

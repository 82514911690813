import { ElementRenderers, ElementRenderersContext } from './element-renderers-context';

import { ElementRendererProps } from '../element-renderer/element-renderer.props.interface';
import { FieldTypes } from '../form-definition';
import { useContext } from 'react';

export const useElementRenderers = (): ElementRenderers => useContext(ElementRenderersContext);
export const useElementRenderer = (type: FieldTypes | null): React.FC<ElementRendererProps> | null => {
  const renderers = useElementRenderers();

  if (!type) {
    return null;
  }

  return renderers[type] ?? null;
};

import { FormSchema } from '../form-definition';
import { buildYup } from 'schema-to-yup';
import { useMemo } from 'react';

export const useValidationSchema = (
  definition: FormSchema | null,
): ReturnType<typeof buildYup> | null => useMemo(() => {
  if (!definition?.schema) {
    return null;
  }

  try {
    return buildYup(definition.schema, undefined);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return null;
  }
}, [definition]);

import { createContext } from 'react';

export interface FormRendererConfig {
  isEditMode?: boolean;
  parentKey?: string;
}

export const defaultConfig: FormRendererConfig = {};

export const FormRendererConfigContext = createContext<FormRendererConfig>(defaultConfig);

import { ItemRendererProps } from '../item-renderer';
import { LabelItem } from '../form-definition';
import React from 'react';
import { useLabelDefinition } from '../schema-context';

export interface LabelRendererProps extends ItemRendererProps {
  definition: LabelItem;
}

export const LabelRenderer: React.FC<LabelRendererProps> = ({
  definition,
}: LabelRendererProps): JSX.Element | null => {
  const labelDefinition = useLabelDefinition(definition.key);

  if (!labelDefinition) {
    return null;
  }

  const { variant: Component, label } = labelDefinition;

  return (
    <Component>{label}</Component>
  );
};

import { FormikHandlers, useFormikContext } from 'formik';

import { Key } from '../../form-definition';
import { get } from '../../utils';
import { useParentKey } from '../../form-renderer-config-context/hooks';

interface FieldFormikInfo<T> extends Pick<FormikHandlers, 'handleChange' | 'handleBlur'> {
  value: T;
  error: string;
  key: string;
}

export const useFieldFormikInfo = <T = string>(fieldKey: Key, defaultValue: unknown = ''): FieldFormikInfo<T> => {
  const parentKey = useParentKey();
  const key = parentKey ? `${parentKey}.${fieldKey}` : fieldKey.toString();
  const {
    values, errors, handleChange, handleBlur, touched,
  } = useFormikContext<Record<Key, string>>();
  const value: unknown = get(values, key) ?? defaultValue;
  const error = get(touched, key) ? ((get(errors, key) as string) ?? '').toString() : '';

  return {
    key,
    error,
    handleChange,
    handleBlur,
    value: value as T,
  };
};

import { FormRendererConfig, FormRendererConfigContext } from './form-renderer-config-context';

import { useContext } from 'react';

export const useConfig = (): FormRendererConfig => useContext(FormRendererConfigContext);

export const useIsEditMode = (): boolean => {
  const { isEditMode } = useConfig();

  return isEditMode ?? false;
};

export const useParentKey = (): string | null => {
  const { parentKey } = useConfig();

  return parentKey ?? null;
};

import {
  CheckboxRenderer,
  DateRenderer,
  ElementRendererProps,
  InputRenderer,
  SelectRenderer,
} from '../element-renderer';

import { FieldTypes } from '../form-definition';
import { createContext } from 'react';

export type ElementRenderers = Record<FieldTypes, React.FC<ElementRendererProps>>

export const defaultElementRenderers: ElementRenderers = {
  input: InputRenderer as React.FC<ElementRendererProps>,
  checkbox: CheckboxRenderer as React.FC<ElementRendererProps>,
  date: DateRenderer as React.FC<ElementRendererProps>,
  datetime: DateRenderer as React.FC<ElementRendererProps>,
  select: SelectRenderer as React.FC<ElementRendererProps>,
};

export const ElementRenderersContext = createContext<ElementRenderers>(defaultElementRenderers);

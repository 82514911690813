/* eslint-disable no-restricted-syntax */
import {
  FieldDefinition,
  FormLabel,
  FormSchema,
  FormSection,
  Key,
} from '../form-definition';
import { useContext, useMemo } from 'react';

import { SchemaContext } from './schema-context';
import { SchemaObject } from 'openapi3-ts';

export const useSchema = (): FormSchema => useContext(SchemaContext);

export const useSectionDefinition = (key: Key): FormSection | null => {
  const schema = useSchema();

  return schema.form?.sections?.[key] ?? null;
};

export const useFieldDefinition = (key: Key): FieldDefinition | null => {
  const schema = useSchema();

  return schema.fields?.[key] ?? null;
};

export const useLabelDefinition = (key: Key): FormLabel | null => {
  const schema = useSchema();

  return schema.form?.labels?.[key] ?? null;
};

export const useFieldRequired = (key: Key): boolean => {
  const { schema } = useSchema();

  return useMemo((): boolean => {
    if (!schema) {
      return false;
    }

    let objectSchema: SchemaObject | undefined = schema;

    for (const path of key.toString().split('.').filter(Boolean)) {
      const isRequired = objectSchema?.required?.includes?.(path) ?? false;

      if (!isRequired) {
        return false;
      }

      if ((objectSchema?.properties?.[path] as SchemaObject)?.type === 'object') {
        objectSchema = objectSchema?.properties?.[path];
      } else {
        return isRequired;
      }
    }

    return false;
  }, [schema, key]);
};

import { FormDateElement } from '../../../form-definition';
import { get } from '../../../utils';
import { useFormikContext } from 'formik';
import { useParentKey } from '../../../form-renderer-config-context/hooks';

export const useDateLimit = (date?: string, propKey?: string): string | undefined => {
  const parentKey = useParentKey();
  const { values } = useFormikContext();

  if (date) {
    return date;
  }

  if (!propKey) {
    return undefined;
  }

  const key = parentKey ? `${parentKey}.${propKey}` : propKey;

  return get(values, key) as string;
};

export const useMinDate = ({
  afterDate: date, afterPropKey: propKey,
}: Pick<FormDateElement, 'afterDate' | 'afterPropKey'>): string | undefined => useDateLimit(date, propKey);

export const useMaxDate = ({
  beforeDate: date, beforePropKey: propKey,
}: Pick<FormDateElement, 'beforeDate' | 'beforePropKey'>): string | undefined => useDateLimit(date, propKey);
